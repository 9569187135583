import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Input, Nav, NavItem, NavLink} from "reactstrap5";
import {NavLink as RNavLink, useHistory} from "react-router-dom5";
import {generateUrl, routes} from "user/helpers/routes";
import {FormattedMessage} from "react-intl";
import {useUser} from "user/helpers/user";
import Icon from "user/components/Icon";
import classnames from "classnames";
import * as Config from "user/helpers/config";
import {USER_ROLE_MESSAGES, USER_ROLE} from "../../../user/helpers/constants";

const UserNav = ({onSelect, showCodeModal, frontend}) => {
    const [user, role, logout, setRole] = useUser();
    const codeRole = role === USER_ROLE.STUDENT_CODE;
    const history = useHistory();
    const roles = user.roles;

    const changeRole = useCallback((role) => {
        setRole(role);
        window.localStorage.setItem('userSetRole', role);

        if (frontend) {
            return;
        }

        if (role === USER_ROLE.PARENT) {
            history.push(generateUrl(routes.children, {}));
        } else {
            history.push(generateUrl(routes.homepage, {}));
        }

    }, [setRole, history, frontend]);

    const clickOnMenu = useCallback((notForCode) => {

        onSelect();

        if (notForCode) {
            showCodeModal();
        }

    }, [onSelect, showCodeModal]);

    return (
        <>
            {roles && roles.length > 1 &&
                <div className={"nav role-selection"}>
                    <div className={"w-100"}>
                        <div className={frontend ? "px-4" : "pe-4"}>
                            <Input type={"select"} value={role} onChange={e => changeRole(e.currentTarget.value)}>
                                {roles.map((opt, key) => {
                                    if (React.isValidElement(USER_ROLE_MESSAGES[opt])) {
                                        return React.cloneElement(USER_ROLE_MESSAGES[opt], {
                                            key,
                                        }, message => <option key={key} value={opt}>{message}</option>);
                                    }

                                    return (<option key={key} value={opt}>{USER_ROLE_MESSAGES[opt]}</option>)
                                })}
                            </Input>
                        </div>
                    </div>
                </div>}
            <Nav vertical>
                {role !== USER_ROLE.PARENT &&
                    <>
                        <FormattedMessage defaultMessage={"Nástěnka"}>
                            {message => <NavItem>
                                <NavLink tag={frontend ? 'a' : RNavLink}
                                         href={frontend ? Config.get('basename') + generateUrl(routes.dashboard) : null}
                                         to={frontend ? null : generateUrl(routes.dashboard)}
                                         onClick={() => clickOnMenu(true)}
                                         className={classnames({'text-secondary': codeRole})}
                                         title={message}>
                                    <Icon icon={"dashboard"} size={"md"}/>
                                    <span className={"px-3"}>
                        {message}
                    </span>
                                </NavLink>
                            </NavItem>}
                        </FormattedMessage>
                        {(role === USER_ROLE.TEACHER || user?.classroomMembers?.length != 0) &&
                            <FormattedMessage defaultMessage={"Moje úkoly"}>
                                {message => <NavItem>
                                    <NavLink tag={frontend ? 'a' : RNavLink}
                                             href={frontend ? Config.get('basename') + generateUrl(routes.assignments) : null}
                                             to={frontend ? null : generateUrl(routes.assignments)}
                                             onClick={() => clickOnMenu(false)}
                                             title={message}>
                                        <Icon icon={"blocks-bl"} size={"md"}/>
                                        <span className={"px-3"}>
                        {message}
                                            <Icon icon={"badge-premium"} size={"lg"} className="ms-1 icon-menu-badge"/>
                    </span>
                                    </NavLink>
                                </NavItem>}
                            </FormattedMessage>
                        }
                        {role !== USER_ROLE.TEACHER && role !== USER_ROLE.PARENT &&
                            <FormattedMessage defaultMessage={"Moje odznaky"}>
                                {message => <NavItem>
                                    <NavLink tag={frontend ? 'a' : RNavLink}
                                             href={frontend ? Config.get('basename') + generateUrl(routes.dashboardAchievements) : null}
                                             to={frontend ? null : generateUrl(routes.dashboardAchievements)}
                                             onClick={() => clickOnMenu(true)}
                                             className={classnames({'text-secondary': codeRole})}
                                             title={message}>
                                        <Icon icon={"avatar"} size={"md"}/>
                                        <span className={"px-3"}>
                        {message}
                                            <Icon icon={"badge-premium"} size={"lg"} className="ms-1 icon-menu-badge"/>
                    </span>
                                    </NavLink>
                                </NavItem>}
                            </FormattedMessage>
                        }
                        <FormattedMessage defaultMessage={"Moje třídy"}>
                            {message => <NavItem>
                                <NavLink tag={frontend ? 'a' : RNavLink}
                                         href={frontend ? Config.get('basename') + generateUrl(routes.classrooms) : null}
                                         to={frontend ? null : generateUrl(routes.classrooms)}
                                         onClick={() => clickOnMenu(true)}
                                         className={classnames({'text-secondary': codeRole})}
                                         title={message}>
                                    <Icon icon={"doors-bl"} size={"md"}/>
                                    <span className={"px-3"}>
                        {message}
                                        <Icon icon={"badge-premium"} size={"lg"} className="ms-1 icon-menu-badge"/>
                    </span>
                                </NavLink>
                            </NavItem>}
                        </FormattedMessage>
                    </>
                }
                {((role !== USER_ROLE.PARENT && role === USER_ROLE.TEACHER) || (user?.classroomMembers?.length != 0 && role !== USER_ROLE.STUDENT_OLD)) &&
                    <FormattedMessage defaultMessage={"Moje zprávy"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.messages) : null}
                                     to={frontend ? null : generateUrl(routes.messages)}
                                     onClick={() => clickOnMenu(true)}
                                     className={classnames({'text-secondary': codeRole})}
                                     title={message}>
                                <Icon icon={"message"} size={"md"}/>
                                <span className={"px-3"}>
                    {message}
                                    <Icon icon={"badge-premium"} size={"lg"} className="ms-1 icon-menu-badge"/>
                </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {[USER_ROLE.STUDENT_OLD, USER_ROLE.STUDENT_YOUNG, USER_ROLE.STUDENT_CODE, USER_ROLE.TEACHER].includes(role) &&
                    <FormattedMessage defaultMessage={"Historie procvičování"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.history) : null}
                                     to={frontend ? null : generateUrl(routes.history)}
                                     onClick={() => clickOnMenu(true)}
                                     className={classnames({'text-secondary': codeRole})}
                                     title={message}>
                                <Icon icon={'history'} size={"md"}/>
                                <span className={"px-3"}>
                    {message}
                </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {role === USER_ROLE.PARENT &&
                    <FormattedMessage defaultMessage={"Moje děti"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.children) : null}
                                     to={frontend ? null : generateUrl(routes.children)}
                                     onClick={() => clickOnMenu(true)}
                                     className={classnames({'text-secondary': codeRole})}
                                     title={message}>
                                <Icon icon={"user-bl"} size={"md"}/>
                                <span className={"px-3"}>
                            {message}
                        </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {(role === USER_ROLE.PARENT || role === USER_ROLE.TEACHER) &&
                    <FormattedMessage defaultMessage={"Novinky"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink} to={generateUrl(routes.news)}
                                     onClick={() => clickOnMenu(true)}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.news) : null}
                                     className={classnames({'text-secondary': codeRole})}
                                     title={message}>
                                <Icon icon={"newsletter"} size={"md"}/>
                                <span className={"px-3 position-relative"}>
                                {message}
                                    {user?.countUnreadNews > 0 &&
                                        <span
                                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                    {user.countUnreadNews}
                                  </span>}
                        </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {(role === USER_ROLE.PARENT || role === USER_ROLE.TEACHER) &&
                    <FormattedMessage defaultMessage={"Objednávky"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink} to={generateUrl(routes.orders)}
                                     onClick={() => clickOnMenu(true)}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.orders) : null}
                                     title={message}>
                                <Icon icon={"order"} size={"md"}/>
                                <span className={"px-3 position-relative"}>
                                {message}
                        </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {(role === USER_ROLE.PARENT || role === USER_ROLE.TEACHER) &&
                    <FormattedMessage defaultMessage={"Licence"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink} to={generateUrl(routes.licences)}
                                     onClick={() => clickOnMenu(true)}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.licences) : null}
                                     title={message}>
                                <Icon icon={"licence"} size={"md"}/>
                                <span className={"px-3 position-relative"}>
                                {message}
                        </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {role !== USER_ROLE.STUDENT_CODE &&
                    <FormattedMessage defaultMessage={"Nastavení"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.settings) : null}
                                     to={frontend ? null : generateUrl(routes.settings)}
                                     onClick={() => clickOnMenu(true)}
                                     className={classnames({'text-secondary': codeRole})}
                                     title={message}>
                                <Icon icon={"settings"} size={"md"}/>
                                <span className={"px-3"}>
                            {message}
                        </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {[USER_ROLE.STUDENT_OLD, USER_ROLE.STUDENT_YOUNG].includes(role) &&
                    <FormattedMessage defaultMessage={"Přidat třídu"}>
                        {message => <NavItem>
                            <NavLink tag={frontend ? 'a' : RNavLink}
                                     href={frontend ? Config.get('basename') + generateUrl(routes.classroomAdd) : null}
                                     to={frontend ? null : generateUrl(routes.classroomAdd)}
                                     title={message}>
                                <Icon icon={'plus-bl'} size={"md"}/>
                                <span className={"px-3"}>
                    {message}
                </span>
                            </NavLink>
                        </NavItem>}
                    </FormattedMessage>
                }
                {!frontend && <NavItem className={"only-toogled"}>
                    <NavLink tag={'a'} href={Config.get('exerciseUri')}>
                        <Icon icon={"search"} size={"md"}/>
                    </NavLink>
                </NavItem>}
            </Nav>
        </>
    );
};

UserNav.propTypes = {
    onSelect: PropTypes.func,
    showCodeModal: PropTypes.func,
    frontend: PropTypes.bool
};

UserNav.defaultProps = {
    onSelect: () => {
    },
    showCodeModal: () => {
    },
    frontend: false
}

export default UserNav;
